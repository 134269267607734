import React, { useState } from "react";
import { fetchFHIR, createClinicalRecord } from "../utils/api";
import { useNavigate } from "react-router-dom";

const CreateClinicalRecord = () => {
  const [recordData, setRecordData] = useState({
    patientIdentifier: "",
    patientId: "",
    patientName: "",
    patientLastName: "",
    description: "",
    category: "optometry",
    date: "",
    visualAcuity: "",
    intraocularPressure: "",
    diagnosis: "",
    treatment: "",
  });

  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  const handleFetchPatient = async () => {
    try {
      const response = await fetchFHIR(`/Patient?identifier=${recordData.patientIdentifier}`);
      if (response.entry && response.entry.length > 0) {
        const patient = response.entry[0].resource;
        setRecordData({
          ...recordData,
          patientId: patient.id,
          patientName: patient.name[0]?.given?.[0] || "",
          patientLastName: patient.name[0]?.family || "",
        });
        setMessage("Paciente encontrado.");
      } else {
        setMessage("Paciente no encontrado.");
      }
    } catch (error) {
      setMessage("Error al buscar el paciente. Intente nuevamente.");
      console.error("Error al buscar el paciente:", error);
    }
  };

  const validateFields = () => {
    const requiredFields = [
      recordData.patientId,
      recordData.date,
      recordData.visualAcuity,
      recordData.intraocularPressure,
      recordData.diagnosis,
      recordData.treatment,
    ];

    return requiredFields.every((field) => field && field.trim() !== "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      setMessage("Por favor, complete todos los campos obligatorios.");
      return;
    }

    const observationData = {
      resourceType: "Observation",
      subject: {
        reference: `Patient/${recordData.patientId}`,
      },
      status: "final",
      category: [
        {
          coding: [
            {
              system: "http://terminology.hl7.org/CodeSystem/observation-category",
              code: recordData.category,
              display: "Optometry",
            },
          ],
        },
      ],
      code: {
        coding: [
          {
            system: "http://loinc.org",
            code: "75323-6",
            display: "Optometric observation",
          },
        ],
      },
      effectiveDateTime: recordData.date,
      component: [
        {
          code: {
            coding: [
              { system: "http://loinc.org", code: "32485-4", display: "Visual Acuity" },
            ],
          },
          valueString: recordData.visualAcuity,
        },
        {
          code: {
            coding: [
              { system: "http://loinc.org", code: "43185-9", display: "Intraocular Pressure" },
            ],
          },
          valueString: recordData.intraocularPressure,
        },
        {
          code: {
            coding: [
              { system: "http://loinc.org", code: "75323-6", display: "Diagnosis" },
            ],
          },
          valueString: recordData.diagnosis,
        },
        {
          code: {
            coding: [
              { system: "http://loinc.org", code: "75324-4", display: "Treatment Plan" },
            ],
          },
          valueString: recordData.treatment,
        },
      ],
    };

    try {
      await createClinicalRecord(observationData);
      setMessage("Historia cl�nica creada con �xito.");
      setRecordData({
        patientIdentifier: "",
        patientId: "",
        patientName: "",
        patientLastName: "",
        description: "",
        category: "optometry",
        date: "",
        visualAcuity: "",
        intraocularPressure: "",
        diagnosis: "",
        treatment: "",
      });
    } catch (error) {
      setMessage("Error al crear la historia cl�nica. Intente nuevamente.");
      console.error("Error al crear la historia cl�nica:", error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f5fafd",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        maxWidth: "700px",
        margin: "40px auto",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h2
        style={{
          color: "#0066cc",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Crear Historia Cl�nica (Optometr�a)
      </h2>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "15px" }}
      >
        <input
          type="text"
          name="patientIdentifier"
          placeholder="C�dula del Paciente"
          value={recordData.patientIdentifier}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <button
          type="button"
          onClick={handleFetchPatient}
          style={{
            padding: "10px",
            backgroundColor: "#0066cc",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            transition: "background-color 0.3s ease",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#004999")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#0066cc")}
        >
          Buscar Paciente
        </button>
        <input
          type="text"
          name="patientName"
          placeholder="Nombre del Paciente"
          value={recordData.patientName}
          readOnly
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#e9ecef",
          }}
        />
        <input
          type="text"
          name="patientLastName"
          placeholder="Apellido del Paciente"
          value={recordData.patientLastName}
          readOnly
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#e9ecef",
          }}
        />
        <textarea
          name="description"
          placeholder="Descripci�n de la Observaci�n"
          value={recordData.description}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <input
          type="date"
          name="date"
          placeholder="Fecha de la Observaci�n"
          value={recordData.date}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <input
          type="text"
          name="visualAcuity"
          placeholder="Agudeza Visual"
          value={recordData.visualAcuity}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <input
          type="text"
          name="intraocularPressure"
          placeholder="Presi�n Intraocular"
          value={recordData.intraocularPressure}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <textarea
          name="diagnosis"
          placeholder="Diagn�stico"
          value={recordData.diagnosis}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <textarea
          name="treatment"
          placeholder="Plan de Tratamiento"
          value={recordData.treatment}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <button
          type="submit"
          style={{
            padding: "10px",
            backgroundColor: "#0066cc",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            transition: "background-color 0.3s ease",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#004999")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#0066cc")}
        >
          Crear Historia Cl�nica
        </button>
        <button
          type="button"
          onClick={() => navigate("/dashboard")}
          style={{
            padding: "10px",
                      backgroundColor: "#dc3545",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontWeight: "bold",
          transition: "background-color 0.3s ease",
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#a71d2a")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#dc3545")}
      >
        Volver al Men�
      </button>
    </form>
    {message && (
      <p
        style={{
          marginTop: "20px",
          color: message.includes("�xito") ? "green" : "red",
          fontWeight: "bold",
        }}
      >
        {message}
      </p>
    )}
  </div>
);
};

export default CreateClinicalRecord;

