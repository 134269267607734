import React, { useState } from "react";
import { fetchFHIR } from "../utils/api";

const ViewClinicalHistory = () => {
  const [patientIdentifier, setPatientIdentifier] = useState(""); // C�dula del paciente
  const [patientId, setPatientId] = useState(""); // ID del paciente
  const [records, setRecords] = useState([]); // Lista de historias cl�nicas
  const [selectedRecord, setSelectedRecord] = useState(null); // Historia cl�nica seleccionada
  const [message, setMessage] = useState("");

  const fetchPatientId = async () => {
    try {
      const response = await fetchFHIR(`/Patient?identifier=${patientIdentifier}`);
      if (response.entry && response.entry.length > 0) {
        const patient = response.entry[0].resource;
        setPatientId(patient.id);
        return patient.id;
      } else {
        throw new Error("Paciente no encontrado.");
      }
    } catch (error) {
      setMessage("Error al buscar el paciente. Verifique la c�dula.");
      console.error("Error al buscar el paciente:", error);
      throw error;
    }
  };

  const fetchClinicalRecords = async (patientId) => {
    try {
      const response = await fetchFHIR(`/Observation?subject=Patient/${patientId}`);
      if (response.entry && response.entry.length > 0) {
        setRecords(response.entry.map((entry) => entry.resource));
        setMessage("");
      } else {
        setRecords([]);
        setMessage("No se encontraron historias cl�nicas para este paciente.");
      }
    } catch (error) {
      setMessage("Error al recuperar el historial cl�nico. Intente nuevamente.");
      console.error("Error al recuperar el historial cl�nico:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("Buscando historial cl�nico...");
    setSelectedRecord(null);
    try {
      const patientId = await fetchPatientId();
      await fetchClinicalRecords(patientId);
    } catch {
      setRecords([]);
    }
  };

  const handleViewRecord = async (recordId) => {
    setMessage("Cargando historia cl�nica...");
    try {
      const response = await fetchFHIR(`/Observation/${recordId}`);
      setSelectedRecord(response);
      setMessage("");
    } catch (error) {
      setMessage("Error al cargar la historia cl�nica.");
      console.error("Error al cargar la historia cl�nica:", error);
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", maxWidth: "800px", margin: "auto" }}>
      <h2 style={{ textAlign: "center", color: "#004aad" }}>Ver Historial Cl�nico</h2>
      <form onSubmit={handleSubmit} style={{ marginBottom: "20px", textAlign: "center" }}>
        <input
          type="text"
          placeholder="C�dula del Paciente"
          value={patientIdentifier}
          onChange={(e) => setPatientIdentifier(e.target.value)}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            marginRight: "10px",
          }}
        />
        <button
          type="submit"
          style={{
            backgroundColor: "#004aad",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          Buscar
        </button>
      </form>
      {message && (
        <p
          style={{
            textAlign: "center",
            color: message.includes("�xito") ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {message}
        </p>
      )}
      {records.length > 0 && (
        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#f2f2f2" }}>ID</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#f2f2f2" }}>Fecha</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#f2f2f2" }}>Descripci�n</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#f2f2f2" }}>Acci�n</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => (
              <tr key={record.id}>
                <td style={{ border: "1px solid #ccc", padding: "10px" }}>{record.id}</td>
                <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {record.effectiveDateTime || "Sin fecha"}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                  {record.component?.find((comp) => comp.code.coding[0].code === "75323-6")?.valueString ||
                    "Sin descripci�n"}
                </td>
                <td style={{ border: "1px solid #ccc", padding: "10px", textAlign: "center" }}>
                  <button
                    onClick={() => handleViewRecord(record.id)}
                    style={{
                      backgroundColor: "#28a745",
                      color: "#fff",
                      padding: "5px 10px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Ver Historia
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {selectedRecord && (
        <div style={{ marginTop: "20px" }}>
          <h3 style={{ color: "#004aad" }}>Detalles de la Historia Cl�nica</h3>
          <p>
            <strong>ID:</strong> {selectedRecord.id}
          </p>
          <p>
            <strong>Fecha:</strong> {selectedRecord.effectiveDateTime || "Sin fecha"}
          </p>
          {selectedRecord.component && (
            <ul>
              {selectedRecord.component.map((comp, index) => (
                <li key={index}>
                  <strong>{comp.code.coding[0].display}:</strong> {comp.valueString || "Sin datos"}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      <button
        onClick={() => (window.location.href = "/dashboard")}
        style={{
          marginTop: "20px",
          backgroundColor: "#dc3545",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        Volver al Men�
      </button>
    </div>
  );
};

export default ViewClinicalHistory;
