const FHIR_BASE_URL = "http://54.237.146.58:8080/fhir"; // URL base del servidor FHIR
const API_BASE_URL = "http://54.237.146.58/api"; // URL base para otras rutas del backend

export const fetchFHIR = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${FHIR_BASE_URL}${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
        ...(options.headers || {}),
      },
      ...options,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Error FHIR ${response.status}: ${
          errorData.issue?.[0]?.diagnostics || response.statusText
        }`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error en fetchFHIR:", error.message);
    throw error;
  }
};

export const fetchBackend = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
        ...(options.headers || {}),
      },
      ...options,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Error API ${response.status}: ${
          errorData.message || response.statusText
        }`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error en fetchBackend:", error.message);
    throw error;
  }
};

export const createPatient = async (patientData) => {
  console.log("Datos enviados al backend:", patientData);
  return fetchBackend("/patient", {
    method: "POST",
    body: JSON.stringify({
      ...patientData,
    }),
  });
};

export const getPatient = async (patientId) => {
  if (!patientId || isNaN(patientId)) {
    throw new Error("El ID del paciente no es v�lido.");
  }
  return fetchFHIR(`/Patient/${patientId}`, {
    method: "GET",
  });
};

export const updatePatient = async (patientId, updatedData) => {
  if (!patientId || isNaN(patientId)) {
    throw new Error("El ID del paciente no es v�lido.");
  }
  return fetchFHIR(`/Patient/${patientId}`, {
    method: "PUT",
    body: JSON.stringify({
      resourceType: "Patient",
      ...updatedData,
    }),
  });
};

export const deletePatient = async (patientId) => {
  if (!patientId || isNaN(patientId)) {
    throw new Error("El ID del paciente no es v�lido.");
  }
  return fetchFHIR(`/Patient/${patientId}`, {
    method: "DELETE",
  });
};

export const registerUser = async (userData) => {
  return fetchBackend("/register", {
    method: "POST",
    body: JSON.stringify(userData),
  });
};

export const loginUser = async (credentials) => {
  return fetchBackend("/login", {
    method: "POST",
    body: JSON.stringify(credentials),
  });
};

export const createClinicalRecord = async (observationData) => {
  return fetchFHIR("/Observation", {
    method: "POST",
    body: JSON.stringify({
      resourceType: "Observation",
      ...observationData,
    }),
  });
};

/**
 * Busca todas las historias cl�nicas (`Observation`) relacionadas con un paciente.
 * @param {string} patientId - ID del paciente.
 * @returns {Array} - Lista de recursos `Observation`.
 */
export const searchClinicalRecords = async (patientId) => {
  if (!patientId || isNaN(patientId)) {
    throw new Error("El ID del paciente no es v�lido.");
  }
  const response = await fetchFHIR(`/Observation?subject=Patient/${patientId}`);
  if (!response.entry || response.entry.length === 0) {
    console.warn("No se encontraron observaciones para el paciente:", patientId);
    return [];
  }
  return response.entry.map((entry) => entry.resource);
};

/**
 * Obtiene un recurso `Observation` por ID.
 * @param {string} recordId - ID de la historia cl�nica a obtener.
 * @returns {object} - Recurso `Observation` del servidor FHIR.
 */
export const getClinicalRecord = async (recordId) => {
  if (!recordId) {
    throw new Error("El ID de la historia cl�nica no es v�lido.");
  }
  const response = await fetchFHIR(`/Observation/${recordId}`, { method: "GET" });
  console.log("Datos obtenidos de FHIR:", response); // TEMPORAL: Eliminar en producci�n
  return response;
};

/**
 * Actualiza un recurso `Observation` en FHIR.
 * @param {string} recordId - ID de la historia cl�nica a actualizar.
 * @param {object} updatedData - Objeto con los datos actualizados.
 * @returns {object} - Respuesta del servidor FHIR.
 */
export const updateClinicalRecord = async (recordId, updatedData) => {
  if (!recordId) {
    throw new Error("El ID de la historia cl�nica no es v�lido.");
  }
  return fetchFHIR(`/Observation/${recordId}`, {
    method: "PUT",
    body: JSON.stringify({
      resourceType: "Observation",
      id: recordId, // Se asegura de incluir el ID en el cuerpo
      ...updatedData,
    }),
  });
};
