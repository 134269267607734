import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Menu from "./components/Menu";
import CreatePatient from "./components/CreatePatient";
import CreateClinicalRecord from "./components/CreateClinicalRecord";
import EditClinicalRecord from "./components/EditClinicalRecord";
import ViewClinicalHistory from "./components/ViewClinicalHistory";
import ProtectedRoute from "./components/ProtectedRoute";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Ruta para el login */}
        <Route path="/" element={<Login />} />
        
        {/* Ruta protegida para el dashboard */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Menu />
            </ProtectedRoute>
          }
        />
        
        {/* Ruta para crear pacientes */}
        <Route
          path="/dashboard/create-patient"
          element={
            <ProtectedRoute>
              <CreatePatient />
            </ProtectedRoute>
          }
        />

        {/* Ruta para crear historias cl�nicas */}
        <Route
          path="/dashboard/create-record"
          element={
            <ProtectedRoute>
              <CreateClinicalRecord />
            </ProtectedRoute>
          }
        />

        {/* Ruta para editar historias cl�nicas */}
        <Route
          path="/dashboard/edit-record"
          element={
            <ProtectedRoute>
              <EditClinicalRecord />
            </ProtectedRoute>
          }
        />

        {/* Ruta para visualizar el historial cl�nico */}
        <Route
          path="/dashboard/view-history"
          element={
            <ProtectedRoute>
              <ViewClinicalHistory />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
