import React, { useState } from "react";
import { getClinicalRecord, updateClinicalRecord } from "../utils/api";

const EditClinicalRecord = () => {
  const [recordId, setRecordId] = useState("");
  const [clinicalRecord, setClinicalRecord] = useState(null);
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClinicalRecord({ ...clinicalRecord, [name]: value });
  };

  const handleLoadRecord = async () => {
    setMessage("Cargando historia cl�nica...");
    try {
      const data = await getClinicalRecord(recordId);

      const visualAcuity = data.component?.find(
        (comp) => comp.code.coding[0].code === "32485-4"
      )?.valueString || "";

      const intraocularPressure = data.component?.find(
        (comp) => comp.code.coding[0].code === "43185-9"
      )?.valueString || "";

      const diagnosis = data.component?.find(
        (comp) => comp.code.coding[0].code === "75323-6"
      )?.valueString || "";

      const treatment = data.component?.find(
        (comp) => comp.code.coding[0].code === "75324-4"
      )?.valueString || "";

      setClinicalRecord({
        id: data.id,
        description: data.code?.coding[0]?.display || "",
        date: data.effectiveDateTime || "",
        visualAcuity,
        intraocularPressure,
        diagnosis,
        treatment,
      });

      setMessage("Historia cl�nica cargada con �xito.");
    } catch (error) {
      setMessage("Error al cargar la historia cl�nica. Verifica el ID.");
      console.error("Error al cargar la historia cl�nica:", error);
    }
  };

  const handleUpdateRecord = async () => {
    setMessage("Actualizando historia cl�nica...");
    try {
      if (!clinicalRecord.id) {
        throw new Error("El recurso no tiene un ID v�lido.");
      }

      await updateClinicalRecord(clinicalRecord.id, {
        id: clinicalRecord.id,
        resourceType: "Observation",
        effectiveDateTime: clinicalRecord.date,
        component: [
          {
            code: {
              coding: [{ system: "http://loinc.org", code: "32485-4", display: "Visual Acuity" }],
            },
            valueString: clinicalRecord.visualAcuity,
          },
          {
            code: {
              coding: [{ system: "http://loinc.org", code: "43185-9", display: "Intraocular Pressure" }],
            },
            valueString: clinicalRecord.intraocularPressure,
          },
          {
            code: {
              coding: [{ system: "http://loinc.org", code: "75323-6", display: "Diagnosis" }],
            },
            valueString: clinicalRecord.diagnosis,
          },
          {
            code: {
              coding: [{ system: "http://loinc.org", code: "75324-4", display: "Treatment Plan" }],
            },
            valueString: clinicalRecord.treatment,
          },
        ],
      });

      setMessage("Historia cl�nica actualizada con �xito.");
      setClinicalRecord(null);
      setRecordId("");
    } catch (error) {
      setMessage("Error al actualizar la historia cl�nica. Intente nuevamente.");
      console.error("Error al actualizar la historia cl�nica:", error);
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto", fontFamily: "Arial, sans-serif" }}>
      <h2 style={{ textAlign: "center", color: "#004aad" }}>Editar Historia Cl�nica</h2>
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="ID de la Historia Cl�nica (Observation ID)"
          value={recordId}
          onChange={(e) => setRecordId(e.target.value)}
          style={{
            width: "calc(100% - 20px)",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        />
        <button
          onClick={handleLoadRecord}
          style={{
            backgroundColor: "#004aad",
            color: "#fff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            width: "100%",
          }}
        >
          Cargar Historia Cl�nica
        </button>
      </div>
      {clinicalRecord && (
        <form style={{ display: "grid", gap: "15px" }}>
          <label>Fecha:</label>
          <input
            type="date"
            name="date"
            value={clinicalRecord.date}
            onChange={handleInputChange}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <label>Agudeza Visual:</label>
          <input
            type="text"
            name="visualAcuity"
            value={clinicalRecord.visualAcuity}
            onChange={handleInputChange}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <label>Presi�n Intraocular:</label>
          <input
            type="text"
            name="intraocularPressure"
            value={clinicalRecord.intraocularPressure}
            onChange={handleInputChange}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <label>Diagn�stico:</label>
          <textarea
            name="diagnosis"
            value={clinicalRecord.diagnosis}
            onChange={handleInputChange}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <label>Plan de Tratamiento:</label>
          <textarea
            name="treatment"
            value={clinicalRecord.treatment}
            onChange={handleInputChange}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <button
            type="button"
            onClick={handleUpdateRecord}
            style={{
              backgroundColor: "#28a745",
              color: "#fff",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            Actualizar Datos
          </button>
        </form>
      )}
      <button
        onClick={() => (window.location.href = "/dashboard")}
        style={{
          marginTop: "20px",
          backgroundColor: "#dc3545",
          color: "#fff",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        Volver al Men�
      </button>
      {message && (
        <p style={{ marginTop: "20px", color: message.includes("�xito") ? "green" : "red", fontWeight: "bold" }}>
          {message}
        </p>
      )}
    </div>
  );
};

export default EditClinicalRecord;
