import React, { useState } from "react";
import { createPatient } from "../utils/api";

const CreatePatient = () => {
  const [patientData, setPatientData] = useState({
    firstName: "",
    lastName: "",
    gender: "male",
    birthDate: "",
    phone: "",
    email: "",
    identifier: "",
    address: "",
    maritalStatus: "",
    preferredLanguage: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPatientData({ ...patientData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que todos los campos est�n completos
    const isValid = Object.values(patientData).every((value) => value.trim() !== "");
    if (!isValid) {
      setMessage("Por favor, completa todos los campos antes de enviar.");
      return;
    }

    try {
      console.log("Datos enviados desde el formulario:", patientData);
      await createPatient(patientData);
      setMessage("Paciente creado con �xito.");

      // Redirigir al formulario de creaci�n de historias cl�nicas
      setTimeout(() => {
        window.location.href = "/dashboard/create-record";
      }, 1500);
    } catch (error) {
      setMessage("Error al crear el paciente. Intente nuevamente.");
      console.error("Error al crear el paciente:", error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f5fafd",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        maxWidth: "600px",
        margin: "40px auto",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h2
        style={{
          color: "#0066cc",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Crear Paciente en FHIR
      </h2>
      <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <input
          type="text"
          name="firstName"
          placeholder="Nombre"
          value={patientData.firstName}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <input
          type="text"
          name="lastName"
          placeholder="Apellido"
          value={patientData.lastName}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <select
          name="gender"
          value={patientData.gender}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <option value="male">Masculino</option>
          <option value="female">Femenino</option>
          <option value="other">Otro</option>
        </select>
        <input
          type="date"
          name="birthDate"
          placeholder="Fecha de Nacimiento"
          value={patientData.birthDate}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <input
          type="text"
          name="phone"
          placeholder="Tel�fono"
          value={patientData.phone}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <input
          type="email"
          name="email"
          placeholder="Correo Electr�nico"
          value={patientData.email}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <input
          type="text"
          name="identifier"
          placeholder="Identificador (C�dula)"
          value={patientData.identifier}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <input
          type="text"
          name="address"
          placeholder="Direcci�n"
          value={patientData.address}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <select
          name="maritalStatus"
          value={patientData.maritalStatus}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <option value="">Estado Civil</option>
          <option value="single">Soltero</option>
          <option value="married">Casado</option>
          <option value="divorced">Divorciado</option>
          <option value="widowed">Viudo</option>
        </select>
        <input
          type="text"
          name="preferredLanguage"
          placeholder="Idioma Preferido"
          value={patientData.preferredLanguage}
          onChange={handleChange}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
        <button
          type="submit"
          style={{
            padding: "10px",
            backgroundColor: "#0066cc",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontWeight: "bold",
            transition: "background-color 0.3s ease",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#004999")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#0066cc")}
        >
          Crear Paciente
        </button>
      </form>
      {message && (
        <p
          style={{
            marginTop: "15px",
            textAlign: "center",
            color: message.includes("�xito") ? "#28a745" : "#dc3545",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default CreatePatient;
