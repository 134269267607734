import React, { useState } from "react";
import axios from "axios";

const Login = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post("http://54.237.146.58/api/login", {
        username: formData.username,
        password: formData.password,
      });
      setMessage("Inicio de sesi�n exitoso");
      localStorage.setItem("token", response.data.token);
      window.location.href = "/dashboard";
    } catch (error) {
      const errorMsg =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "Error al iniciar sesi�n";
      setMessage("Error al iniciar sesi�n: " + errorMsg);
    }
  };

  const handleRegister = async () => {
    try {
      await axios.post("http://54.237.146.58/api/register", {
        username: formData.username,
        password: formData.password,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
      });
      setMessage("Registro exitoso. Ahora puedes iniciar sesi�n.");
      setIsRegister(false);
    } catch (error) {
      const errorMsg =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "Error al registrar usuario";
      setMessage("Error al registrar usuario: " + errorMsg);
    }
  };

  return (
    <div
      style={{
        maxWidth: "400px",
        margin: "auto",
        textAlign: "center",
        backgroundColor: "#f3f8ff",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h1 style={{ color: "#0066cc", fontFamily: "Arial, sans-serif" }}>
        {isRegister ? "Registro" : "Inicio de Sesi�n"}
      </h1>
      <p style={{ color: "#ff4d4d", fontWeight: "bold" }}>{message}</p>
      {isRegister && (
        <>
          <input
            type="text"
            name="first_name"
            placeholder="Nombre"
            value={formData.first_name}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              margin: "10px 0",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          <input
            type="text"
            name="last_name"
            placeholder="Apellido"
            value={formData.last_name}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              margin: "10px 0",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          <input
            type="email"
            name="email"
            placeholder="Correo Electr�nico"
            value={formData.email}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              margin: "10px 0",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
        </>
      )}
      <input
        type="text"
        name="username"
        placeholder="Usuario"
        value={formData.username}
        onChange={handleInputChange}
        style={{
          width: "100%",
          padding: "10px",
          margin: "10px 0",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />
      <input
        type="password"
        name="password"
        placeholder="Contrase�a"
        value={formData.password}
        onChange={handleInputChange}
        style={{
          width: "100%",
          padding: "10px",
          margin: "10px 0",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />
      {isRegister ? (
        <button
          onClick={handleRegister}
          style={{
            width: "100%",
            backgroundColor: "#0066cc",
            color: "#fff",
            padding: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          Registrarse
        </button>
      ) : (
        <button
          onClick={handleLogin}
          style={{
            width: "100%",
            backgroundColor: "#0066cc",
            color: "#fff",
            padding: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          Iniciar Sesi�n
        </button>
      )}
      <p style={{ marginTop: "10px" }}>
        {isRegister ? (
          <span
            onClick={() => setIsRegister(false)}
            style={{
              cursor: "pointer",
              color: "#0066cc",
              textDecoration: "underline",
            }}
          >
            �Ya tienes cuenta? Inicia sesi�n aqu�
          </span>
        ) : (
          <span
            onClick={() => setIsRegister(true)}
            style={{
              cursor: "pointer",
              color: "#0066cc",
              textDecoration: "underline",
            }}
          >
            �No tienes cuenta? Reg�strate aqu�
          </span>
        )}
      </p>
    </div>
  );
};

export default Login;
