import React from "react";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "auto",
        textAlign: "center",
        backgroundColor: "#f3f8ff",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h2 style={{ color: "#0066cc", fontFamily: "Arial, sans-serif" }}>Men� Principal</h2>
      <ul
        style={{
          listStyleType: "none",
          padding: 0,
          marginTop: "20px",
        }}
      >
        <li
          style={{
            margin: "10px 0",
            padding: "10px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
          onClick={() => navigate("/dashboard/create-patient")}
        >
          Crear Paciente
        </li>
        <li
          style={{
            margin: "10px 0",
            padding: "10px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
          onClick={() => navigate("/dashboard/create-record")}
        >
          Crear Historia Cl�nica
        </li>
        <li
          style={{
            margin: "10px 0",
            padding: "10px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
          onClick={() => navigate("/dashboard/edit-record")}
        >
          Editar Historia Cl�nica
        </li>
        <li
          style={{
            margin: "10px 0",
            padding: "10px",
            backgroundColor: "#0066cc",
            color: "#fff",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
          onClick={() => navigate("/dashboard/view-history")}
        >
          Ver Historial Cl�nico
        </li>
      </ul>
    </div>
  );
};

export default Menu;
